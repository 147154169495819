import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
// tslint:disable-next-line: import-blacklist
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
// import { catchError } from 'rxjs/operators';

import { HttpClientService } from '../http_client_service/http-client.service';
import { ConsToken } from '../constToken';
import { Respuesta } from '../../_interfaces/respuesta';
import { PresupuestoInterface } from '../../_interfaces/gestion_presupuestal/presupuesto';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService {
  constructor(private http: HttpClientService) { }

  guardar(data: PresupuestoInterface): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/presupuesto', data);
  }

  listarPresupuestos(soccioPaciolo: number): Observable<PresupuestoInterface> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + '/presupuesto/findPresupuestos/' + soccioPaciolo).pipe(
      map(respuesta => {
        const presupuestos = <Respuesta>respuesta;
        return presupuestos.data.map(element => {
          const presupuesto = <PresupuestoInterface>element;
          presupuesto.nombre = element.nombre.toUpperCase();
          presupuesto.fechaInicio = formatDate(element.fechaInicio, 'yyyy-MM-dd', 'en-US');
          presupuesto.fechaFin = formatDate(element.fechaFin, 'yyyy-MM-dd', 'en-US');
          return presupuesto;
        });
      })
    );
  }

  editar(data: PresupuestoInterface): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServicePagosFactura + '/presupuesto', data);
  }

  listar(socioPacciolo: number): Observable<PresupuestoInterface> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + '/presupuesto/findAll/' + socioPacciolo).pipe(
      map(respuesta => {
        const presupuestos = <Respuesta>respuesta;
        return <PresupuestoInterface>presupuestos.data;
      })
    );
  }

  getPresupuesto(id: number): Observable<PresupuestoInterface> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + '/presupuesto/findOne/' + id).pipe(
      map(element => {
        const presupuesto = <Respuesta>element;
        return <PresupuestoInterface>presupuesto.data;
      })
    );
  }

  obtenerRubros(idPresupuesto: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + '/presupuesto/findRubroByPresupuesto/' + idPresupuesto);
    // return this.http.get(ConsToken.urlMicroServicePagosFactura + '/presupuesto/findRubroByPresupuesto/' + idPresupuesto).pipe(
    //   map(respuesta => {
    //     const rubro = <Respuesta>respuesta;
    //     return rubro.data;
    //   })
    // );
  }

  listarRubros(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + '/presupuesto/findRubroAndPresupuesto/' + socioPacciolo).pipe(
      map(respuesta => {
        const rubros = <Respuesta>respuesta;
        return rubros.data;
      })
    );
  }

  dataConfigCreateBudgetRegister(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlEntryPoint + `/gestion-presupuestal/dataConfigCreateBudgetRegister/${socioPacciolo}`);
  }
}
