import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';
import * as moment from 'moment';
import { Fuentes } from '@app/_interfaces/contabilidad/fuentes-interface';
import { Respuesta } from '@app/_interfaces/respuesta';
import { ConsToken } from '@app/_services/constToken';
@Injectable({
  providedIn: 'root'
})
export class FuentesService {

  constructor(
    private http: HttpClientService
  ) {
    moment.locale('es');
  }

  saveFuentes(data: Fuentes[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceGestionContable + '/fuente', data);
  }

  fuentesList(id: number): Observable<Fuentes> {
    return this.http.get(ConsToken.urlMicroServiceGestionContable + '/fuente/' + id);
  }
}
