import { Injectable } from '@angular/core';

import { HttpClientService } from '../http_client_service/http-client.service';
import { ConsToken } from '../constToken';

@Injectable({
  providedIn: 'root'
})
export class FuenteService {
  constructor(private http: HttpClientService) { }
    
  guardarFuentes(data: any): any {
    return this.http.post(ConsToken.urlNode + '/fuentes/guardarFuentes', data);
  }

  listar_fuentes(): any {
    return this.http.get(ConsToken.urlNode + '/fuentes/get_fonts');
  }

  getFontbyId(id: number): any {
    return this.http.get(ConsToken.urlNode + '/fuentes/get_fontbyId/' + id);
  }

  edit_font(fuente: any): any {
    return this.http.put(ConsToken.urlNode + '/fuentes/edit_font', fuente);
  }

  getConsecutivo(id: number): any {
    return this.http.get(ConsToken.urlNode + '/fuentes/f_consecutivo/' + id);
  }

  cambiarEstadoFuente(data: any): any {
    return this.http.put(ConsToken.urlNode + '/fuentes/cambiarEstadoFuente', data);
  }
}
