import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';
import { ConsToken } from '@app/_services/constToken';

import { RecoleccionType } from '@app/redAmbiental/types/recoleccion.type';

import { Respuesta } from '@app/_interfaces/respuesta';
import { RutasHasDireccionesType } from '@app/redAmbiental/types/rutasHasDirecciones.type';
import { DireccionType } from '@app/persona/types/direccion.type';
import { DateRangeType } from '@app/_interfaces/dateRange.type';

@Injectable({
  providedIn: 'root'
})
export class RecoleccionService {

  private rutasHasDirecciones$ = new Subject<RutasHasDireccionesType[]>();
  private fechaRecoleccion$ = new Subject<string>();
  private infiltratorsList$ = new Subject<DireccionType[]>();
  private addressHarvest$ = new Subject<DireccionType[]>();

  constructor(
    private http: HttpClientService
  ) { }

  /**
   * @description
   * Función que guarda las recolecciones
   * @param harvest Contiene un array de Recoleccion
   * @returns Un Observable de tipo Respuesta
   */
  saveHarvest(harvest: RecoleccionType[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + '/recoleccion/saveHarvest', harvest);
  }

  saveHarvest2(harvest: RecoleccionType[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + '/recoleccion/saveHarvestApp', harvest);
  }

  /**
   * @description
   * Función que actualiza una recolección
   * @param data Contiene la información de la recolección que se va a editar
   * @returns Un Observable de tipo Respuesta
   */
  editHarvest(data: any): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServiceRedAmbiental + '/recoleccion/editHarvest', data);
  }

  /**
   * @description
   * Función que busca las recoleccion de los clientes dependiendo la información de un filtro
   * @param data Contiene la información del filtro de la vista recoleccion-residuos
   * @returns Un Observable de tipo Respuesta
   */
  recoleccionList(data: any): Observable<any> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + '/recoleccion', data);
  }

  getAllLastRecolect(data: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getAllLastRecolect/${data}`)
  }

  getLastRecolect(direccionId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getLastRecolect/${direccionId}`)
  }

  onGetAddressByDate(date: any): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/onGetAddressByDate/${date}`)
  }


  /**
   * @description
   * Función que obtiene las recolecciones de una dirección
   * @param direccionId Contiene el ID de una direccion
   */
  getHarvestByDireccion(direccionId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getHarvestByDireccionId/${direccionId}`);
  }

  filterWithDate(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/filterWithDate`, data)
  }

  filterDireccion(direccionId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/filterDireccion/${direccionId}`);
  }

  getAllWithDate(data:any): Observable<Respuesta>{
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getAllWithDate`, data)
  }

  getAllRecolect(data: number): Observable<Respuesta>{
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getAllRecolect/${data}`)
  }

  getAllDataHarvest(data: any): Observable<Respuesta>{
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getAllDataHarvest`,data)
  }

  /**
   * @description
   * Función que busca las recolecciones de una dirección incluyendo las sucursales
   * @param direccionId Contiene el ID de una dirección
   */
  getHarvestByPersonIncludingBranchOffices(direccionId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getHarvestByPersonIncludingBranchOffices/${direccionId}`);
  }

  /**
   * @description
   * Función que obtiene las recolecciones por tipo de residuo agrupagos por mes en un rango de fecha
   * @param data Contiene un objeto con las propiedades { direccionId, dateRange }
   */
  getHarvestByTypeGroupedByMonthIncludingBranchOffices(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getHarvestByTypeGroupedByMonthIncludingBranchOffices`, data);
  }

  getHarvestByAllData(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getHarvestByAllData`, data);
  }

  /**
   * @description
   * Función que obtiene las recolecciones de una dirección, por tipo de residuo agrupagos por mes, en un rango de fecha
   * @param data Contiene un objeto con las propiedades { direccionId, dateRange }
   */
  getHarvestByTypeGroupedByMonthByAddress(data): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getHarvestByTypeGroupedByMonthByAddress`, data);
  }

  /**
   * @description
   * Funcion que obtiene la Informe Autoridad Ambiental
   * @param dateRange Contiene un objeto de tipo DateRangeType
   */
  getInformeAutoridadAmbiental(dateRange: DateRangeType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getInformeAutoridadAmbiental`, dateRange);
  }

  /***********************INICIO GRUPO DE FUNCIONES LOCALES ******************************/

  /**
   * @description
   * Función que envia las rutasHasRecolección a todos los observer pero de manera local
   * @param rutasHasDirecciones Contiene un array de tipo RutasHasDireccionesType
   */
  sendRutasHasDirecciones(rutasHasDirecciones: RutasHasDireccionesType[]): void {
    this.rutasHasDirecciones$.next(rutasHasDirecciones);
  }

  /**
   * @description
   * Función que obtiene las rutasHasRecoleccion pero de manera local
   * @returns Un array de tipo RutasHasDireccionesType
   */
  getRutasHasDirecciones(): Observable<RutasHasDireccionesType[]> {
    return this.rutasHasDirecciones$.asObservable();
  }

  /**
   * @description
   * Función que envia un Array de direcciones a todos observer pero de manera local
   * @param address Contiene un Objeto de tipo DireccionType
   */
  sendAddressHarvest(address: DireccionType[]): void {
    this.addressHarvest$.next(address);
  }

  /**
   * @description
   * Función que obtiene un Array de direcciones pero de manera local
   * @returns Un Array de tipo DireccionType
   */
  getAddressHarvest(): Observable<DireccionType[]> {
    return this.addressHarvest$.asObservable();
  }

  /**
   * @description
   * Función que envia las direcciones (infiltrados) a todos los observer pero de manera local
   * @param direcciones Contiene un array de tipo DireccionType
   */
  sendInfiltratorsList(direcciones: DireccionType[]): void {
    this.infiltratorsList$.next(direcciones);
  }

  /**
   * @description
   * Función que obtiene las direcciones (infiltrados) pero de manera local
   * @returns Un array de tipo DireccionType
   */
  getInfiltratorsList(): Observable<DireccionType[]> {
    return this.infiltratorsList$.asObservable();
  }
}
