import { Injectable } from '@angular/core';

// tslint:disable-next-line: import-blacklist
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { EmailType } from '@app/persona/types/email.type';
import { TelefonoType } from '@app/persona/types/telefono.type';
import { SendEmailType } from '@app/persona/types/sendEmail.type';

import { ConsToken } from '../constToken';
import { HttpClientService } from '../http_client_service/http-client.service';
import { Respuesta } from '../../_interfaces/respuesta';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private http: HttpClientService,
    private httpClient: HttpClient
  ) { }

  getInfoConfigCreateUpdatePerson(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/common/getInfoConfigCreateUpdatePerson/${socioPacciolo}`);
  }

  getDepartmentsByCountry(pais: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/common/getDepartmentsByCountry/${pais}`);
  }

  getCitysByDepartment(depart: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/common/getCitysByDepartment/${depart}`);
  }

  getNeighborhoodByCity(city: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/common/getNeighborhoodByCity/${city}`);
  }

  findAddresWithContract(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceContratos + `/common/findAddresWithContract/${socioPacciolo}`);
  }

  getAdministrativeDepartment(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/common/getAdministrativeDepartment/${socioPacciolo}`);
  }

  getTypeFonts(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/common/getTypeFonts`);
  }

  // getAddressTypeOneTwo(socioPacciolo: number): Observable<Respuesta> {
  //   return this.http.get(ConsToken.urlMicroServicePersonas + `/common/getAddressTypeOneTwo/${socioPacciolo}`);
  // }

  saveEmails(data: EmailType[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + `/common/saveEmails`, data);
  }

  savePhones(data: TelefonoType[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + `/common/savePhones`, data);
  }

  sendEmail(data: SendEmailType): Observable<Respuesta> {
    return this.httpClient.post<Respuesta>(ConsToken.urlMicroServicePersonas + `/send-emails/send`, data);
  }

  /**
   * @description
   * Función que obtiene los tipos de identificación
   */
  getIdentificationTypes(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/common/getIdentificationTypes`);
  }

  /**
   * @description
   * Función que busca la relación entre el consumo y la facturación;
   * esto para saber si a un cliente se le recoge y no se le factura y viceversa
   * @param fecha Contiene un string en formato fecha
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getConsumptionInvoiceRelation(fecha: string, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/common/getConsumptionInvoiceRelation/${fecha}/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que obtiene la lista de los tipos de conceptos por los que se puede
   * emitir una nota crédito o débito
   */
  getConceptsNotes(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/common/getConceptsNotes`);
  }
}
