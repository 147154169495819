import { Injectable } from '@angular/core';

// tslint:disable-next-line: import-blacklist
import { Observable } from 'rxjs';

import { Respuesta } from '@app/_interfaces/respuesta';
import { HttpClientService } from '@app/_services/http_client_service/http-client.service';
import * as moment from 'moment';

import { CentroDeCostoPrincipal } from '@app/_interfaces/contabilidad/centroDeCosto-interface';
import { CentroDeCostoAuxiliar } from '@app/_interfaces/contabilidad/centroDeCostoAuxiliar-interface';
import { ConsToken } from '@app/_services/constToken';

@Injectable({
  providedIn: 'root'
})
export class CentroDeCostoPrincipalService {

  constructor(
    private http: HttpClientService
  ) {
    moment.locale('es');
  }

  saveCentroDeCostos(data: CentroDeCostoPrincipal[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceGestionContable + '/centroDeCostoPrincipal', data);
  }

  listCentroDeCostosById(id: number): Observable<CentroDeCostoPrincipal> {
    return this.http.get(ConsToken.urlMicroServiceGestionContable + '/centroDeCostoPrincipal/' + id);
  }

  saveCentroDeCostoAuxiliar(data: CentroDeCostoAuxiliar[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceGestionContable + '/centroDeCostoAuxiliar', data);
  }

  listCentroDeCostosAuxiliarByCentroDeCostoPrincipalId(id: number): Observable<CentroDeCostoAuxiliar> {
    return this.http.get(ConsToken.urlMicroServiceGestionContable + '/centroDeCostoAuxiliar/' + id);
  }
}
