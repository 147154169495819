import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';
import { ConsToken } from '@app/_services/constToken';

import { Respuesta } from '@app/_interfaces/respuesta';
import { CajasType } from '@app/cajas/types/cajas.type';
import { CajasHasPersonaType } from '@app/cajas/types/cajasHasPersona.type';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CajasService {

  private cajasHasPersona$ = new Subject<CajasType>();
  constructor(private http: HttpClientService) { }

  save(data: CajasType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/cajas/save', data);
  }

  savePersonasHasCajas(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/cajas/savePersonasHasCajas', data);
  }

  /**
   * @description
   * Función que busca todas las cajas de un socioPacciolo
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getAllBoxes(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/cajas/getAllBoxes/${socioPacciolo}`);
  }

  getCajasById(id: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/cajas/getCajasById/${id}`);
  }

  saveBoxMovement(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + `/cajas/saveBoxMovement`, data);
  }

  /**
   * @description
   * Función que busca los movimientos de una caja
   * @param id Contiene el ID de una caja
   */
  getMovementsBox(id: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/cajas/getMovementsBox/${id}`);
  }

  /**
   * @description
   * Función que elimina a una persona de una caja
   * @param chp Contiene la información de una caja has persona
   */
  deletePersonasHasCajas(chp: CajasHasPersonaType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + `/cajas/deletePersonasHasCajas`, chp);
  }

  openCloseBox(data: CajasType, socioPacciolo: number): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServicePagosFactura + `/cajas/openCloseBox/${socioPacciolo}`, data);
  }

  /**
   * @description
   * Función que busca un listado de caja por socioPacciolo y estado
   * @param socioPacciolo Contiene la información el ID de un socioPacciolo
   * @param estado Contiene un tipo de estado de caja Ej: (0 o 1)
   */
  getBosexByStatus(socioPacciolo: number, estado: string): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/cajas/getBosexByStatus/${socioPacciolo}/${estado}`);
  }

  /**
   * @description
   * Función que obtiene las cajas de un socioPacciolo
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getBoxesBySelect(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/cajas/getBoxesBySelect/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que obtiene las personas que hacen parte de una caja
   * @param cajaId Contiene el ID de una caja
   */
  getPersonHasBoxes(cajaId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/cajas/getPersonHasBoxes/${cajaId}`);
  }

  /*###############################INICIO FUNCIONES QUE FUNCIONAN EN LOCAL####################################*/

  /**
   * @description
   * Función que si la persona loggeada es el administrador de la caja
   * para poder cerrar y eliminar personas de una caja
   * @param caja Contiene la información de una caja
   */
  validPermiso(caja: CajasType): boolean {
    if (caja.personaId === environment.persona) {
      return true;
    }
    return false;
  }

  /**
   * @description
   * Función que envia una caja a todos los observer pero de manera LOCAL
   * @param caja Contiene la información de una caja
   */
  sendCaja(caja: CajasType): void {
    this.cajasHasPersona$.next(caja);
  }

  /**
   * @description
   * Función que obtiene una caja pero de manera LOCAL
   */
  getCaja(): Observable<CajasType> {
    return this.cajasHasPersona$.asObservable();
  }
}
