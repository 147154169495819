// tslint:disable-next-line: import-blacklist
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { HttpClientService } from '../http_client_service/http-client.service';
import { ConsToken } from '../constToken';
import { Bodegas } from '../../_interfaces/inventario/bodegas';

import { Respuesta } from '../../_interfaces/respuesta';
// tslint:disable-next-line: origin-ordered-imports

@Injectable({
  providedIn: 'root'
})
export class AlmacenamientoService {
  constructor(private http: HttpClientService) { }

  save(bodegas: Bodegas): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceInventario + '/bodega', bodegas);
  }

  get(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceInventario + '/bodega/' + socioPacciolo).pipe(
      map(bodegas => {
        const bodega = <Respuesta>bodegas;
        return bodega.data;
      })
    );
  }

  list_almacenamiento(): any {
    return this.http.get(ConsToken.urlNode + '/almacenamiento/almacenamientos');
  }
}
