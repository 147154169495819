import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
// tslint:disable-next-line: import-blacklist
import { Observable } from 'rxjs';

import { HttpClientService } from '../http_client_service/http-client.service';
import { ConsToken } from '../constToken';
import { Respuesta } from '../../_interfaces/respuesta';
import { Grupos } from '../../_interfaces/inventario/grupos';

@Injectable({
  providedIn: 'root'
})
export class GruposService {
  constructor(private http: HttpClientService) {}

  get(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceInventario + '/grupos').pipe(
      map((res) => {
        const grupos = <Respuesta>res;
        return grupos.data;
      })
    );
  }

  getProduct(): Observable<Respuesta> {
    return this.http
      .get(ConsToken.urlMicroServiceInventario + '/productos')
      .pipe(
        map((response) => {
          const productos = <Respuesta>response; // convierto mi respuesta a un array de tipo Productos
          return productos.data.map((prod) => {
            prod.nombre = prod.nombre.toUpperCase();
            return prod;
          });
        })
      );
  }

  save(grupo: Grupos): Observable<Respuesta> {
    return this.http.post(
      ConsToken.urlMicroServiceInventario + '/grupos',
      grupo
    );
  }

  edit(grupo: any): any {
    return this.http.put(ConsToken.url + '/edit_grupo', grupo);
  }

  listar(): any {
    return this.http.get(ConsToken.urlNode + '/grupos/listar_grupos');
  }

  findAllGroupsbySocio(socioId: number): Observable<Respuesta> {
    return this.http.get(
      ConsToken.urlMicroServiceInventario +
        `/grupos/findAllGroupsbySocio/${socioId}`
    );
  }

  editGroup(group: Grupos): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServiceInventario + '/grupos', group);
  }

  deleteGroup(groupId: number): Observable<Respuesta> {
    return this.http.delete(ConsToken.urlMicroServiceInventario +
      `/grupos/${groupId}`);
  }
}
