import { Injectable } from '@angular/core';

import { HttpClientService } from '../http_client_service/http-client.service';
import { ConsToken } from '../constToken';

@Injectable({
  providedIn: 'root'
})
export class CentroDeCostosService {

  constructor(private http: HttpClientService) { }

  guardarCentroCosto(data: any): any {
    return this.http.post(ConsToken.urlNode + '/centro_costos/guardarCentroCosto', data);
  }

  listarCentroDeCostos(): any {
    return this.http.get(ConsToken.urlNode + '/centro_costos/listarCentroDeCostos');
  }

  editeCeco(data: any): any {
    return this.http.put(ConsToken.urlNode + '/centro_costos/editeCeco', data);
  }

  cambiarEstadoCeco(data: any): any {
    return this.http.put(ConsToken.urlNode + '/centro_costos/cambiarEstadoCeco', data);
  }
}
