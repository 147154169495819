import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';

import { ConsToken } from '@app/_services/constToken';
import { Respuesta } from '@app/_interfaces/respuesta';
import { CargosType } from '@app/persona/types/cargos.type';

@Injectable({
  providedIn: 'root'
})
export class CargosService {

  constructor(private http: HttpClientService) { }

  /**
   * @description
   * Función que guarda un cargo
   * @param cargo Contiene un Objeto de tipo CargosTypes
   * @returns Un Objeto de tipo Respuesta
   */
  saveCargo(cargo: CargosType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + '/caracteristicas/saveCargo', cargo);
  }

  /**
   * @description
   * Función que obtiene un listado de cargos por el ID del socioPacciolo
   * y por el tipo (Empleado o Tercero)
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   * @param type Contiene un String
   * @returns Un Array de tipo Respuesta
   */
  getPositionByType(socioPacciolo: number, type: string): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/cargos/getPositionByType/${socioPacciolo}/${type}`);
  }
}
