import { Injectable } from '@angular/core';
import * as moment from 'moment';

// tslint:disable-next-line: import-blacklist
import { Observable } from 'rxjs';

import { Respuesta } from '@app/_interfaces/respuesta';
import { HttpClientService } from '@app/_services/http_client_service/http-client.service';
import { CatalogoCuenta } from '@app/_interfaces/contabilidad/subCuentas-interface';

import { CuentaAuxiliar } from '@app/_interfaces/contabilidad/cuenta-auxiliar-interface';
import { CuentaAuxiliarDePersonas } from '@app/_interfaces/contabilidad/cuenta-auxiliar-interface-persona';
import { ConsToken } from '@app/_services/constToken';

@Injectable({
  providedIn: 'root'
})
export class CatalogoDeCuentasService {

  constructor(
    private http: HttpClientService
  ) {
    moment.locale('es');
  }

  saveCatalogoDeCuentas(data: CatalogoCuenta[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceGestionContable + '/catalogoDeCuentaSocioPasiolo', data);
  }

  listCatalogoDeCuentas(id: number): Observable<CatalogoCuenta> {
    return this.http.get(ConsToken.urlMicroServiceGestionContable + '/catalogoDeCuentaSocioPasiolo/' + id);
  }

  listCatalogoDeCuentasAuxiliares(id: number): Observable<CuentaAuxiliar> {
    return this.http.get(ConsToken.urlMicroServiceGestionContable + '/catalogoCuentaAuxiliar/' + id);
  }

  saveCatalogoDeCuentasAuxiliares(data: CuentaAuxiliar[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceGestionContable + '/catalogoCuentaAuxiliar', data);
  }

  saveCatalogoDeCuentasAuxiliaresDePersonas(data: CuentaAuxiliarDePersonas[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceGestionContable + '/CatalogoDeCuentasAuxiliaresDePersona', data);
  }

  getConsecutivoByCuenta(codigo: string, socioPasiolo: number): Observable<any> {
    return this.http.get(ConsToken.urlMicroServiceGestionContable + '/catalogoCuentaAuxiliar/getConsecutivoByCuenta/' + codigo +
      '/' + socioPasiolo);
  }

  getConsecutivoCuentaByPersona(codigo: string, personaId: number): Observable<any> {
    return this.http.get(ConsToken.urlMicroServiceGestionContable + '/CatalogoDeCuentasAuxiliaresDePersona/getConsecutivoCuentaByPersona/'
      + codigo + '/' + personaId);
  }

}
