import { Injectable } from '@angular/core';
// tslint:disable-next-line: import-blacklist

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';

import { HttpClientService } from '../http_client_service/http-client.service';
import { Respuesta } from '../../_interfaces/respuesta';
import { CreditoInterface } from '../../_interfaces/credito/credito-interface';
import { ConsToken } from '../constToken';

@Injectable({
  providedIn: 'root'
})
export class CreditoService {

  constructor(private http: HttpClientService) { }

  /**
   * Función que guarda un credito
   * @param data Contiene la información de un credito
   */
  save(data: CreditoInterface): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/credito/save', data);
  }

  /**
   * Función que busca lo creditos para un select en una vista
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getAll(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/credito/getAll/${socioPacciolo}`);
  }

  /**
   * Función que busca lo creditos para un select en una vista
   * @param socioPacciolo Contiene el ID de un socioPacciolo 
   */
  getAllForSelect(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/credito/getAllForSelect/${socioPacciolo}`);
  }
}
